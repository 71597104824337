import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil, withLatestFrom} from 'rxjs/operators';

import {AuthFacade} from '@auth/+state';
import {AppFacade} from '@app/+state';
import {CoreFacade} from '@app/core/+state';
import {ConfigurationFacade} from '@configuration/+state';

import {MatSidenav} from '@angular/material/sidenav';

import {Screen} from '../../models';
import * as fromStorage from '@core/+state/core.storage';
import {ProfileFacade} from '@profile/+state';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sideNav: MatSidenav;

  destroy$: Subject<boolean> = new Subject<boolean>();
  year: string = new Date().getFullYear().toString();
  logoUrl: string;
  clicks = 0;
  timer = null;
  timeout = 350;
  accessList = [];

  constructor(
    public app: AppFacade,
    public auth: AuthFacade,
    public core: CoreFacade,
    public configuration: ConfigurationFacade,
    public profile: ProfileFacade
  ) {
  }

  ngOnInit() {
    this.core.isThemeDark$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDark) => {
        this.logoUrl = this.getLogoUrl(isDark);
      });
    // If none of organization selected in context switching menu
    this.auth.providerUser$.pipe(withLatestFrom(this.auth.providerId$))
      .subscribe(([providers, providerId]) => {
        if (providers && providers.length > 0) {
          let index = 0;
          const localProviderId = fromStorage.getOrganizationLocal();
          if (localProviderId) {
            index = providers.findIndex(i => i.organization.id === localProviderId);
            index = index === -1 ? 0 : index;
          }
          const provider = providers[index]?.organization;
          this.auth.setProvider(provider);
        }
      });
    this.auth.globalRole$.subscribe((roles) => {
      const navItems = [
        {display: 'Administration', role: 'site_admin'},
        {display: 'Dashboard', role: 'site_admin'},
        {display: 'Users', role: 'site_admin'},
        {display: 'Providers', role: 'site_admin'},
        {display: 'Actions', role: 'site_admin'},
        {display: 'Organizations', role: 'user_provider'},
        {display: 'My Organization', role: 'user_provider'},
        {display: 'Organizations', role: 'provider_admin'},
        {display: 'My Organization', role: 'provider_admin'},
        {display: 'Reports', role: 'provider_reports'},
        {display: 'Organizations', role: 'provider_reports'},
        {display: 'Users', role: 'provider_reports'},
        {display: 'Clients', role: 'user'},
      ];
      const role = roles ? roles[0] : '';
      this.accessList = navItems.filter((item) => role === 'site_admin' ||
        item.role === role
        || item.role === 'user'
      ).map(value => value.display);
    });
    this.profile.loadProfile();
  }

  getLogoUrl(isDark: boolean): string {
    if (isDark) {
      return '../../../../assets/sharescape-dark-theme.png';
    } else {
      return '../../../../assets/sharescape-light-theme.png';
    }
  }

  logoLoaded(loaded: boolean) {
    this.core.setLogoLoaded(loaded);
  }

  login() {
    this.auth.login();
  }

  logout() {
    this.auth.logout();
  }

  navigate(screen: Screen) {
    if (screen.device === 'handset') {
      this.sideNav.close();
    }
  }

  eula() {
    this.core.openEULA();
  }

  suggestProvider() {
    this.core.openSuggestProviderContainer();
  }

  referPatient() {
    this.core.openReferPatientContainer();
  }

  privacyPolicy() {
    this.core.openPrivacyPolicy();
  }

  // Show Git commit hash
  showCommitHash() {
    this.core.showCommitHash();
  }

  // Trigger the action when user click at least 5 time
  hiddenAction() {
    clearTimeout(this.timer);
    this.clicks++;
    this.timer = setTimeout(() => {
      if (this.clicks >= 5) {
        this.showCommitHash();
      }
      this.clicks = 0;
    }, this.timeout);
  }

  selectProvider(provider) {
    this.auth.setProvider(provider);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
