<mat-card>
  <mat-card-title>
    <div class="header mat-elevation-z6">
      <div class="center-title">Portal Login</div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <form *ngIf="!magicLink" [formGroup]="loginForm" (ngSubmit)="login()">
      <p>
        <mat-form-field>
          <input
            type="text"
            matInput
            autocomplete="off"
            placeholder="Username"
            formControlName="username"
            required
          />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <input
            matInput
            placeholder="Password"
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            autocomplete="off"
            required
          />
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </mat-form-field>
      </p>
      <div class="button">
        <button type="submit" mat-flat-button color="accent">
          Login
        </button>
      </div>
    </form>

    <form *ngIf="magicLink" [formGroup]="magicLinkForm" (ngSubmit)="sendMagicLink()">
      <p>
        <mat-form-field>
          <input
            type="text"
            matInput
            autocomplete="off"
            placeholder="Username"
            formControlName="username"
            required
          />
        </mat-form-field>
      </p>

      <div class="magic-link-section">
        <button type="submit" mat-flat-button
                color="accent"
                [disabled]="!magicLinkForm.valid">
          <mat-icon>email</mat-icon> Send Me a Magic Link
        </button>
        <mat-icon class="help" matTooltip="We'll email you a secure link which will log you into the app. An alternative to using your password.">
          help
        </mat-icon>
      </div>
    </form>

  </mat-card-content>
  <mat-card-footer>
    <a class="magic-link-foot"  *ngIf="!magicLink" (click)="switchLink()">
      Use a Magic Link
    </a>

    <a class="forgot-password"  *ngIf="!magicLink" [routerLink]="['/password-reset-request']">
      Forgot Password
    </a>

    <button class="use-password-foot" mat-flat-button *ngIf="magicLink" (click)="switchLink()"
            color="accent">
      <mat-icon class="material-icons-outlined">lock</mat-icon> Use a Password
    </button>
  </mat-card-footer>
</mat-card>
