import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, combineLatest} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import * as fromActions from './core.actions';
import * as fromSelectors from './core.selectors';
import {Screen, SnackBarInfo, Theme, NavItem} from '../models';
import {GitVersionService} from '@core/services';


@Injectable({providedIn: 'root'})
export class CoreFacade {
  themes$: Observable<Array<Theme>> = this.store.pipe(
    select(fromSelectors.selectThemes)
  );

  themeName$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectThemeName)
  );

  theme$: Observable<Theme> = this.store.pipe(
    select(fromSelectors.selectTheme)
  );

  isThemeDark$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectIsThemeDark)
  );

  isLogoLoaded$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectLogoLoaded)
  );

  navItems$: Observable<NavItem[]> = this.store.pipe(
    select(fromSelectors.selectNavElements)
  );

  searchOrganization$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectSearchOrganization)
  );

  profileUpdateReminder$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectProfileUpdateReminder)
  );
  ///////////////////////////////////////////////////
  // Create private observables for the screen types
  ///////////////////////////////////////////////////
  private isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  private isHandsetPortrait$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.HandsetPortrait)
    .pipe(map((result) => result.matches));

  private isHandsetLandscape$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.HandsetLandscape)
    .pipe(map((result) => result.matches));

  private isTablet$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Tablet)
    .pipe(map((result) => result.matches));

  private isTabletPortrait$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.TabletPortrait)
    .pipe(map((result) => result.matches));

  private isTabletLandscape$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.TabletLandscape)
    .pipe(map((result) => result.matches));

  private isWebPortrait$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.WebPortrait)
    .pipe(map((result) => result.matches));

  private isWebLandscape$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.WebLandscape)
    .pipe(map((result) => result.matches));

  ////////////////////////////////////////////////////////////
  // Expose the screen$ observable and combines all the
  // private observables information
  ////////////////////////////////////////////////////////////
  screen$: Observable<Screen> = combineLatest([
    this.isHandset$,
    this.isHandsetPortrait$,
    this.isHandsetLandscape$,
    this.isTablet$,
    this.isTabletPortrait$,
    this.isTabletLandscape$,
    this.isWebPortrait$,
    this.isWebLandscape$,
  ]).pipe(
    map(
      ([
         handset,
         handsetPortrait,
         handsetLandscape,
         tablet,
         tabletPortrait,
         tabletLandscape,
         webPortrait,
         webLandscape,
       ]) => {
        const device: string = handset ? 'handset' : tablet ? 'tablet' : 'web';
        const portrait: boolean =
          handsetPortrait || tabletPortrait || webPortrait;
        const landscape: boolean =
          handsetLandscape || tabletLandscape || webLandscape;
        return {device, portrait, landscape};
      }
    ),
    shareReplay()
  );

  constructor(
    private store: Store<any>,
    private breakpointObserver: BreakpointObserver,
    private gitVersionService: GitVersionService,
  ) {
  }

  log(message: string) {
    this.store.dispatch(fromActions.log({message}));
  }

  showSnackbar(snackBarInfo: SnackBarInfo) {
    this.store.dispatch(fromActions.showSnackBar({snackBarInfo}));
  }

  showSnackbarError(snackBarInfo: SnackBarInfo) {
    this.store.dispatch(fromActions.showSnackBarError({snackBarInfo}));
  }

  setTheme(theme: Theme) {
    this.store.dispatch(fromActions.setTheme({theme}));
  }

  setThemeName(themeName: string, isDark: boolean) {
    this.store.dispatch(fromActions.setThemeName({themeName, isDark}));
  }

  setLogoLoaded(logoLoaded: boolean) {
    this.store.dispatch(fromActions.setLogoLoaded({logoLoaded}));
  }

  openFeedback() {
    this.store.dispatch(fromActions.openFeedback());
  }

  saveFeedback(narrative: string) {
    const feedback: any = {feedback: narrative};
    this.store.dispatch(fromActions.saveFeedback({feedback}));
  }

  openEULA() {
    this.store.dispatch(fromActions.openEulaDialog());
  }

  openSuggestProviderContainer() {
    this.store.dispatch(fromActions.openShareScapeInvite());
  }

  openReferPatientContainer() {
    this.store.dispatch(fromActions.openReferPatientDialog());
  }

  openPrivacyPolicy() {
    this.store.dispatch(fromActions.openPrivacyPolicyDialog());
  }

  searchOrganization(search) {
    this.store.dispatch(fromActions.searchOrganization(search));
  }

  resetSearchResult(searchRes) {
    this.store.dispatch(fromActions.searchOrganizationSuccess({searchRes}));
  }

  showCommitHash() {
    const message = this.gitVersionService.getCommitHash();
    const action = 'CLOSE';
    const duration = 0;
    this.showSnackbar({message, action, duration});
  }
}
