import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, from } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { RouterFacade } from '@router/+state';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { LogoutPromptComponent } from '@auth/components';
import { LoggerService } from '@core/services';
import { AuthService } from '../services';
import { AppFacade } from '@app/+state';
import { openEulaDialog, openProfileUpdateReminderNag } from '@core/+state';
import { AuthFacade } from './auth.facade';
import * as jwtDecode from 'jwt-decode';
import * as fromActions from './auth.actions';

@Injectable()
export class AuthEffects {
  @Effect()
  authStateChange$: Observable<Action> = this.afAuth.authState.pipe(
    switchMap(() =>
      this.afAuth.idTokenResult.pipe(
        switchMap((idToken) => {
          if (idToken) {
            const id = idToken.claims.user_id;
            return [
              fromActions.updateLastSeen({ id }), // Update users.last_login
              // fromActions.checkEula({ id }), // Check if the user has accepted the EULA
              fromActions.getUserInformation({ id }),
              fromActions.setAuthState({ idToken }),
            ];
          }
          return [fromActions.setAuthState({ idToken })];
        })
      )
    )
  );

  /**
   * Login Process
   *
   * 1. Login Action sends user to /login
   * 2. When the user clicks login, the app calls the API /auth/login
   *    sending over the credentials.
   * 3. If the API call is successful, then the app takes the intermediate
   *    token from the API call body and tries to sign in with Firebase.
   * 4. If that is successful, then the user is logged in.
   *
   * NOTE:  if the user is switching accounts, the websocket subscriptions
   *        may still be active. Therefore just reload the browser to
   *        cancel any subscriptions.
   */
  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType(fromActions.login),
    map(() => {
      this.router.go({ path: ['login'] });
      location.reload(); // Reload browser to cancel any subscriptions
    })
  );

  @Effect()
  authenticate$ = this.actions$.pipe(
    ofType(fromActions.authenticate),
    tap(() => this.app.setLoading(true)),
    switchMap(({ username, password }) =>
      this.authService.authenticate(username, password).pipe(
        map(({ token }) => fromActions.authenticateSuccess({ token })),
        catchError((err) => {
          // Persist username in error message to track who is trying
          // to login.
          const error = { ...err, user: username };
          return of(fromActions.authenticateError({ error }));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  authenticateError$ = this.actions$.pipe(
    ofType(fromActions.authenticateError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      let message = error.statusText;
      if (error.status === 401) {
        message = 'Check Username and Password.';
        return this.logger.notice(message, error);
      } else if (error.status === 500 || error.status === 0) {
        message = 'Unable to connect to server. Please try again later.';
        return this.logger.critical(message, error);
      } else {
        return this.logger.critical(message, error);
      }
    })
  );

  @Effect()
  authenticateSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.authenticateSuccess),
    switchMap(({ token }) =>
      from(this.afAuth.signInWithCustomToken(token)).pipe(
        map(() => fromActions.firebaseSignInSuccess()),
        catchError((error) => of(fromActions.firebaseSignInError({ error })))
      )
    )
  );

  @Effect()
  getUserInformation$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.getUserInformation),
    switchMap(({ id }) =>
      from(this.authService.getUserInformation(id)).pipe(
        switchMap((userInformation) => {
          if (userInformation?.provider && userInformation.provider.length > 0) {
              return [
                  fromActions.getUserInformationSuccess({userInformation}),
                  fromActions.providerUser({id: userInformation.id})
              ];
          }
          return [
              fromActions.getUserInformationSuccess({userInformation}),
          ];
        }),
        catchError((error) =>
          of(fromActions.getUserInformationError({ error }))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  getUserInformationError$ = this.actions$.pipe(
    ofType(fromActions.magicAuthenticateError),
    map(({ error }) => {
      const message = 'An error occurred getting your account details.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  magicAuthenticate$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.magicAuthenticate),
    tap(() => this.app.setLoading(true)),
    switchMap(({ token }) =>
      from(this.afAuth.signInWithCustomToken(token)).pipe(
        map(() => fromActions.magicAuthenticateSuccess({ token })),
        catchError((error) => of(fromActions.magicAuthenticateError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  magicAuthenticateSuccess$ = this.actions$.pipe(
    ofType(fromActions.magicAuthenticateSuccess),
    tap(() => this.app.setLoading(false)),
    map(({ token }) => {
      const decoded = jwtDecode(token);
      const username =
        decoded && decoded.claims && decoded.claims.username
          ? decoded.claims.username
          : 'NO username';
      this.logger.infoWithUsername(
        username,
        'Login with magic link successful.'
      );
      this.router.go({ path: [`/`] });
    })
  );

  @Effect({ dispatch: false })
  magicAuthenticateError$ = this.actions$.pipe(
    ofType(fromActions.magicAuthenticateError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred signing in with your magic link.';
      this.logger.error(message, error);
      this.router.go({ path: [`/login`] });
    })
  );

  @Effect()
  sendMagicLink$ = this.actions$.pipe(
    ofType(fromActions.sendMagicLink),
    tap(() => this.app.setLoading(true)),
    switchMap(({ username }) =>
      this.authService.sendMagicLink(username).pipe(
        map(() => fromActions.sendMagicLinkSuccess({ username })),
        catchError((err) => {
          // Persist username in error message to track who is trying
          // to login.
          const error = { ...err, user: username };
          return of(fromActions.sendMagicLinkError({ error }));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  sendMagicLinkSuccess$ = this.actions$.pipe(
    ofType(fromActions.sendMagicLinkSuccess),
    tap(() => this.app.setLoading(false)),
    map(({ username }) => {
      const message =
        'A link has been sent to your email. Click the link to login.';
      this.logger.noticeWithUsername(username, message);
    })
  );

  @Effect({ dispatch: false })
  sendMagicLinkError$ = this.actions$.pipe(
    ofType(fromActions.sendMagicLinkError),
    tap(() => this.app.setLoading(false)),
    map((response) => {
      this.app.setLoading(false);
      const message = 'There was an error sending the email.';
      this.logger.error(message, response);
    })
  );

  @Effect({ dispatch: false })
  signInSuccess$ = this.actions$.pipe(
    ofType(fromActions.firebaseSignInSuccess),
    map(() => this.app.setLoading(false))
  );

  @Effect({ dispatch: false })
  firebaseSignInError$ = this.actions$.pipe(
    ofType(fromActions.firebaseSignInError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred signing in.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  requestPasswordReset$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.requestPasswordReset),
    tap(() => this.app.setLoading(true)),
    switchMap(({ username }) =>
      this.authService.resetPasswordRequest(username).pipe(
        map((response) => fromActions.requestPasswordResetSuccess(response)),
        catchError((error) =>
          of(fromActions.requestPasswordResetError({ error }))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  requestPasswordResetSuccess$ = this.actions$.pipe(
    ofType(fromActions.requestPasswordResetSuccess),
    tap(() => this.app.setLoading(false)),
    map((action) => {
      const message = `Email Sent. Check your email for the Reset Password link.`;
      this.logger.notice(message);
      this.router.go({ path: ['/login'] });
    })
  );

  @Effect({ dispatch: false })
  requestPasswordResetError$ = this.actions$.pipe(
    ofType(fromActions.requestPasswordResetError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred requesting a password reset.';
      this.logger.critical(message, error);
      this.router.go({ path: ['/login'] });
    })
  );

  @Effect()
  passwordReset$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.passwordReset),
    tap(() => this.app.setLoading(true)),
    switchMap(({ body }) =>
      this.authService.resetPassword(body).pipe(
        map((response) => fromActions.passwordResetSuccess(response)),
        catchError((error) => of(fromActions.passwordResetError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  passwordResetSuccess$ = this.actions$.pipe(
    ofType(fromActions.passwordResetSuccess),
    tap(() => this.app.setLoading(false)),
    map((action) => {
      const message = `Password reset successfully.`;
      this.logger.notice(message);
      this.router.go({ path: ['/login'] });
    })
  );

  @Effect({ dispatch: false })
  passwordResetError$ = this.actions$.pipe(
    ofType(fromActions.passwordResetError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred resetting password.';
      this.logger.critical(message, error);
      this.router.go({ path: ['/login'] });
    })
  );

  @Effect()
  updateLastSeen$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.updateLastSeen),
    switchMap(({ id }) => {
      return from(this.authService.updateLastSeen(id)).pipe(
        map(() => fromActions.updateLastSeenSuccess()),
        catchError((error) => of(fromActions.updateLastSeenError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  updateLastSeenSuccess$ = this.actions$.pipe(
    ofType(fromActions.updateLastSeenSuccess),
    map(() => {
      const message = 'Last seen updated successfully.';
      this.logger.info(message);
    })
  );

  @Effect({ dispatch: false })
  updateLastSeenError$ = this.actions$.pipe(
    ofType(fromActions.updateLastSeenError),
    map(({ error }) => {
      const message = 'An error occurred updating last login.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  checkEula$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.getUserInformationSuccess),
    switchMap(({ userInformation }) => {
      if (!userInformation.eula_accepted) {
        return [openEulaDialog()];
      } else {
        return [fromActions.checkEulaSuccess(), openProfileUpdateReminderNag()];
      }
    })
  );

  @Effect({ dispatch: false })
  checkEulaError$ = this.actions$.pipe(
    ofType(fromActions.firebaseSignInError),
    map(({ error }) => {
      const message = 'An error occurred getting user EULA status.';
      this.logger.error(message, error);
    })
  );

  // The Effect responds to the Logout Action fired from the Header Component.
  @Effect()
  logoutConfirmation$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.logout),
    exhaustMap(() =>
      // Open a modal dialog to confirm the user wants to logout.
      this.dialogService
        .open(LogoutPromptComponent)
        .afterClosed()
        .pipe(
          map((confirmed) =>
            confirmed
              ? fromActions.logoutConfirmed()
              : fromActions.logoutCancelled()
          )
        )
    )
  );

  @Effect()
  changePassword$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.changePassword),
    tap(() => this.app.setLoading(true)),
    withLatestFrom(this.auth.uid$),
    withLatestFrom(this.afAuth.idToken),
    switchMap(([[{ body }, uid], token]) =>
      this.authService.changePassword(uid, body, token).pipe(
        map(() => fromActions.changePasswordSuccess()),
        catchError((error) => of(fromActions.changePasswordError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  changePasswordSuccess$ = this.actions$.pipe(
    ofType(fromActions.changePasswordSuccess),
    tap(() => this.app.setLoading(false)),
    map(() => {
      const message = 'Password was changed.';
      this.logger.notice(message);
      this.router.go({ path: ['/profile'] });
    })
  );

  @Effect({ dispatch: false })
  changePasswordError$ = this.actions$.pipe(
    ofType(fromActions.changePasswordError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      this.router.go({ path: ['/profile'] });
      let message = 'Password was not changed.';
      if (error.status === 401) {
        message = message + ' Current password was incorrect.';
        return this.logger.notice(message, error);
      } else {
        return this.logger.critical(message, error);
      }
    })
  );

  @Effect()
  providerUser$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.providerUser),
    switchMap(({id}) =>
      from(this.authService.getUserProvider(id)).pipe(
        map((providers) => {
          return fromActions.providerUserSuccess({providers});
        }),
        catchError((error) =>
          of(fromActions.providerUserError({error}))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  providerUserSuccess$ = this.actions$.pipe(
      ofType(fromActions.providerUserSuccess),
      map(() => this.app.setLoading(false))
  );

  @Effect({ dispatch: false })
  changeOrganizationError$ = this.actions$.pipe(
      ofType(fromActions.providerUserError),
      map(({ error }) => {
          const message = 'An error occurred getting Provider User.';
          this.logger.error(message, error);
      })
  );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.logoutConfirmed),
    switchMap(() => {
      return from(this.afAuth.signOut()).pipe(
        map(() => {
          return fromActions.login();
        }),
        catchError((error) => of(fromActions.logoutError({ error })))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private app: AppFacade,
    private auth: AuthFacade,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private dialogService: MatDialog,
    private router: RouterFacade,
    private logger: LoggerService
  ) {}
}
