import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { PREFERENCE_FRAGMENT } from '@preferences/fragments';

export const getPreferences = gql`
  query GetPreferences {
    default: default_preferences {
      id
      type
      body
    }
    user: user_preferences {
      preference {
        ...preferenceFragment
      }
    }
  }
  ${PREFERENCE_FRAGMENT}
`;

export const insertPreference = gql`
  mutation InsertUserPreference($object: user_preferences_insert_input!) {
    insert_user_preferences_one(object: $object) {
      preference {
        ...preferenceFragment
      }
    }
  }
  ${PREFERENCE_FRAGMENT}
`;

export const updatePreference = gql`
  mutation UpdatePreference($id: uuid!, $body: jsonb) {
    update_preferences_by_pk(pk_columns: { id: $id }, _set: { body: $body }) {
      ...preferenceFragment
    }
  }
  ${PREFERENCE_FRAGMENT}
`;

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(private apollo: Apollo) {}

  gePreferences(): Observable<any> {
    return this.apollo.query({ query: getPreferences }).pipe(
      // tap((res) => console.log(res)),
      pluck('data')
    );
  }

  insert(input: any): Observable<any> {
    return this.apollo
      .mutate({ mutation: insertPreference, variables: input })
      .pipe(
        // tap((data) => console.log(`Mutation Insert:`, data)),
        pluck('data', 'insert_user_preferences_one')
      );
  }

  update(input: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: updatePreference,
        variables: { id: input.id, body: input.body },
      })
      .pipe(
        // tap((data) => console.log(`Mutation Update:`, data)),
        pluck('data', 'update_preferences_by_pk')
      );
  }
}
