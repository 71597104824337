<mat-card>
  <mat-card-header class="users-header"
           [ngClass]="(isCreate)?'content-space-between':'content-center'">
    <div class="mat-h3">Users</div>
    <button *ngIf="isCreate"
            mat-mini-fab
            aria-label="Add User"
            (click)="create.emit(selectedOrganization)"
            matTooltip="Add User"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-header>

  <mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="username"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.username }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        First Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.first_name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Last Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.last_name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userGroup">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        User Group
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        <span *ngIf="row.group_id === 'provider_admin'">Admin</span>
        <span *ngIf="row.group_id === 'provider_user'">User</span>

<!--        <ng-container *ngFor="let group of row?.user_groups">-->
<!--          <span *ngIf="(row.user_groups.length > 1);then admin else user"></span>-->
<!--          <ng-template #admin>-->
<!--            <span *ngIf="group?.group_id === 'provider_admin'">Admin</span>-->
<!--          </ng-template>-->
<!--          <ng-template #user>-->
<!--            <span *ngIf="group?.group_id === 'user_provider'">User</span>-->
<!--          </ng-template>-->
<!--        </ng-container>-->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >{{ row.last_name }}, {{ row.first_name }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="lastLogin">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Last Login
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.last_login | date: 'MM/dd/yyyy hh:mm aaa' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="User Details"
          [matTooltipShowDelay]="750"
          (click)="details.emit(row)"
        >
          <mat-icon>details</mat-icon>
        </a>
        <a *ngIf="isEmail"
           type="button"
           mat-icon-button
           color="accent"
           matTooltip="Send Invite"
           [matTooltipShowDelay]="750"
           (click)="sendInvite.emit(row)"
        >
          <mat-icon>email</mat-icon>
        </a>
        <a *ngIf="isDelete"
           type="button"
           mat-icon-button
           color="warn"
           matTooltip="Remove User"
           [matTooltipShowDelay]="750"
           (click)="remove.emit(row)"
        >
          <mat-icon>person</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selected: selectedUser && selectedUser.id === row.id }"
      (click)="selectItem.emit(row)"
      matRipple
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="pageSize"
  ></mat-paginator>
</mat-card>

