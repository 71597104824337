import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CoreFacade } from '@core/+state';
import { Screen } from '@core/models';

@Component({
  selector: 'app-footer',
  template: `
    <mat-toolbar class="footer">
      <a
        *ngIf="contact"
        mat-flat-button
        [href]="contact"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        target="_blank"
        >Contact Us</a
      >
      <a
        *ngIf="privacyPolicy"
        mat-flat-button
        (click)="privacyPolicyClicked.emit()"
        >Privacy Policy</a
      >
      <a
        *ngIf="termsOfService"
        mat-flat-button
        [href]="termsOfService"
        target="_blank"
        >Terms of Service</a
      >
      <span class="fill-remaining-space"></span>
      <a
        mat-flat-button
        (click)="onOpenFeedback()"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >Send Feedback</a
      >
      <mat-icon>copyright</mat-icon>{{ year }} {{ getCopyright() }}
    </mat-toolbar>
  `,
  styles: [
    `
      .footer {
        height: 40px;
        font-size: 12px;
      }

      .fill-remaining-space {
        flex: 1 1 auto;
      }

      mat-icon {
        font-size: 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
      }

      .mat-flat-button {
        font-size: 12px;
        background-color: transparent;
      }

      .hide-column {
        display: none;
      }
    `,
  ],
})
export class FooterComponent {
  @Input() contact: string;
  @Input() privacyPolicy: string;
  @Input() termsOfService: string;
  @Input() screen: Screen;
  @Input() year: string;
  @Output() privacyPolicyClicked: EventEmitter<void> = new EventEmitter();

  constructor(private core: CoreFacade) {}

  getCopyright() {
    return 'ShareScape LLC';
  }

  onOpenFeedback() {
    this.core.openFeedback();
  }
}
