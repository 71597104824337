import { Component, OnInit } from '@angular/core';
import { ProfileFacade } from '@profile/+state';
import { AppFacade } from '@app/+state';
import { RouterFacade } from '@app/router/+state';
import { PhoneNumbersFacade } from '@app/phone-numbers/+state';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-container.component.html',
  styleUrls: ['./profile-container.component.scss'],
})
export class ProfileContainerComponent implements OnInit {
  constructor(
    public profile: ProfileFacade,
    public app: AppFacade,
    private router: RouterFacade,
    private phoneNumbers: PhoneNumbersFacade
  ) {}

  ngOnInit(): void {
    this.profile.loadProfile();
  }

  updateProfile(user: any) {
    this.profile.updateProfile(user);
  }

  phoneNumberCreate(phoneNumber: any) {
    this.phoneNumbers.createUserPhoneNumber(phoneNumber);
  }

  phoneNumberUpdate(phoneNumber: any) {
    this.phoneNumbers.update(phoneNumber);
  }

  phoneNumberDelete(phoneNumber: any) {
    this.phoneNumbers.delete(phoneNumber);
  }
}
