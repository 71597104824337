import { createAction, props } from '@ngrx/store';
import { SnackBarInfo, Theme } from '../models';

export const setLogoLoaded = createAction(
  '[Core] Set Logo Loaded',
  props<{ logoLoaded: boolean }>()
);

/**
 * Log
 */
export const log = createAction('[Core] Log', props<{ message: string }>());
export const logSuccess = createAction(
  '[Core] Log Success',
  props<{ response: any }>()
);
export const logError = createAction(
  '[Core] Log Error',
  props<{ error: string }>()
);

/**
 * Theme Actions
 */
export const setTheme = createAction(
  '[Core] Set Theme',
  props<{ theme: Theme }>()
);
export const setThemeName = createAction(
  '[Core] Set Theme Name',
  props<{ themeName: string; isDark: boolean }>()
);

/**
 * Feedback Actions
 */
export const openFeedback = createAction('[Core] Open Feedback UI');
export const cancelFeedback = createAction('[Core] Cancel Feedback');
export const saveFeedback = createAction(
  '[Core] Save Feedback',
  props<{ feedback: any }>()
);
export const saveFeedbackSuccess = createAction(
  '[Core] Save Feedback Success',
  props<{ feedback: any }>()
);
export const saveFeedbackError = createAction(
  '[Core] Save Feedback Error',
  props<{ error: any }>()
);

/**
 * EULA Action
 */
export const openEulaDialog = createAction('[Core] Open EULA Dialog');
export const eulaAccepted = createAction('[Core] EULA Accepted');
export const eulaCancelled = createAction('[Core] EULA Cancelled');
export const saveEulaAcceptedSuccess = createAction(
  '[Core] Save EULA Accepted Success'
);
export const saveEulaAcceptedError = createAction(
  '[Core] Save EULA Accepted Error',
  props<{ error: any }>()
);

/**
 * Privacy Policy Actions
 */
export const openPrivacyPolicyDialog = createAction(
  '[Core] Open Privacy Policy'
);
export const closePrivacyPolicyDialog = createAction(
  '[Core] Close Privacy Policy'
);

/**
 * Share ShareScape Action
 */
export const openShareScapeInvite = createAction('[Core] Open share ShareScape Dialog');
export const closeShareScapeInvite = createAction('[Core] Close share ShareScape Dialog');
export const submitShareScapeInvite = createAction(
  '[Core] Submit ShareScape invite',
  props<{ shareScapeInvite: any }>()
);
export const submitShareScapeInviteSuccess = createAction(
  '[Core] Submit ShareScape invite Success',
  props<{ shareScapeInvite: any }>()
);
export const submitShareScapeInviteError = createAction(
  '[Core] Submit ShareScape invite Error',
  props<{ error: any }>()
);

/**
 * Refer Patient Action
 */
export const openReferPatientDialog = createAction('[Core] Open Refer Patient Dialog');
export const closeReferPatientDialog = createAction('[Core] Close Refer Patient Dialog');
export const submitReferPatient = createAction(
  '[Core] Submit Refer Patient',
  props<{ referPatient: any }>()
);
export const submitReferPatientSuccess = createAction(
  '[Core] Submit Refer Patient Success',
  props<{ referPatient: any }>()
);
export const submitReferPatientError = createAction(
  '[Core] Submit Refer Patient Error',
  props<{ error: any }>()
);

/**
 * Search provider location Action
 */
export const searchOrganization = createAction(
  '[Core] Search Provider Location',
  props<{ search: any }>()
);

export const searchOrganizationSuccess = createAction(
  '[Core] Search Provider Location Success',
  props<{ searchRes: any }>()
);
export const searchOrganizationError = createAction(
  '[Core] Search Provider Location Error',
  props<{ error: any }>()
);

/**
 * Initial login prompt Action
 */
export const openInitLoginPrompt = createAction('[Core] Open Initial Login Dialog');
export const cancelInitLoginPrompt = createAction('[Core] Close Initial Login Dialog');

/**
 * Profile Actions
 */
export const updateProfile = createAction(
  '[Core] Update Profile',
  props<{ input: any }>()
);
export const updateProfileSuccess = createAction(
  '[Core] Update Profile Success',
  props<{ user: any }>()
);
export const updateProfileError = createAction(
  '[Core] Update Profile Error',
  props<{ error: any }>()
);

/**
 * Update Profile Reminder Actions
 */
export const openProfileUpdateReminderNag = createAction(
  '[Core] Open Profile Update Reminder Nag'
);

export const profileUpdateReminder = createAction(
  '[Core] Profile Update Reminder',
  props<{ value: any }>()
);


/**
 * Snackbar Actions
 */
export const showSnackBar = createAction(
  '[Core] Show Snack Bar',
  props<{ snackBarInfo: SnackBarInfo }>()
);
export const showSnackBarError = createAction(
  '[Core] Show Snack Bar Error',
  props<{ snackBarInfo: SnackBarInfo }>()
);

export const clearState = createAction('[Core] Clear State');
