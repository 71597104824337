// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environment: 'Test Intagleo Systems',
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCnRLGhNkFtLrkDhIRtIsGMumrLAhbOPC8",
    authDomain: "intagleo-systems.firebaseapp.com",
    databaseURL: "https://intagleo-systems.firebaseio.com",
    projectId: "intagleo-systems",
    storageBucket: "intagleo-systems.appspot.com",
    messagingSenderId: "1075001950619",
    appId: "1:1075001950619:web:1f5ba7234544223657a13e",
    measurementId: "G-5PPVTJ3NLR"
  },
  hasura: {
    https: 'https://test-intagleo-systems-api.sharescape.io/v1/graphql',
    wss: 'wss://test-intagleo-systems-api.sharescape.io/v1/graphql',
  },
  apiUrl: 'https://test-intagleo-systems-api.sharescape.io',
  logToConsole: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
